import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {NavLink, HashRouter } from "react-router-dom";
import GetMessageDashboard from "./MessagingDashboard";
import SuperUserDashboard from "./SuperUserDashboard";
import FindUsers from "./FindUsers";


class ServiceSubscription extends Component {
  render() {
    return (
        <HashRouter>     
          <div className="container"   >
                          <NavLink to="/servicessubsocmed">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            Social media
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            <br/>
                            <NavLink to="/servicesubnpps">                              
                            <span className="header-akwaba-rightnavbar-navlinks">
                            News Papers
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            <br/>
                            <NavLink to="/servicesubonarts">
                            <span className="header-akwaba-rightnavbar-navlinks">
                           Online articles
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            <br/>
                            <NavLink to="/servicesubtvcasts">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            TV broadcast
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            <br/>
                            <NavLink to="/servicesubradcasts">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            Radio broadcast
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            <br/>
                            <NavLink to="/servicesubmisc">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            Miscellaneous
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
           </div>
        </HashRouter>
     
    );
  }
}

export default withTranslation()(ServiceSubscription);
