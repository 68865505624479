import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Contact from "../../footer/askus";
import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";
import ServiceSubscription from "./dashboard/ServiceSubscriptionJourn";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";
import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
import BuyTicket from "./booking/BuyATicket";
import CollectAnInviteAsVIP from "./booking/CollectAVIPInvite";
import CollectInvite from "./booking/CollectInvite";
import ConfirmParticipationToEvent from "./booking/CollectInvite";
import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";
import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";
import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";
import Help from "./help";
import Visits from "./UserVisits";

import GeneratePerformance from "./pages/performancesandorders/Performances/GeneratePerformance";
import Performances from "./pages/performancesandorders/Performances/PerformancePage";

class journalismBody
 extends Component {
  render() {
    return (
        <HashRouter> 
          <Route path="/helpjourn" component={Help} />      
          <Route path="/visitsjourn" component={Visits} />              
          <Route path="/loginscreenjourn" component={Loginscreen} />   
          <Route path="/contactjourn" component={Contact} />     
          <Route path="/registeruserjourn" component={RegisterUser} />
          <Route path="/performancesjourn" component={Performances} /> 
          <Route path="/generateperformancejourn" component={GeneratePerformance} /> 
          <Route path="/changepasswordjourn" component={ChangePassword} /> 
          <Route path="/loginjourn" component={Login} /> 
          <Route path="/logoutjourn" component={Logout} /> 
          <Route path="/servicesubjourn" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedjourn" component={ThankYouUploaded} /> 
          <Route path="/uploadassetsjourn" component={UploadAssets} />
          <Route path="/uploadpicturesjourn" component={UploadPictures} />
          <Route path="/uploadvideosjourn" component={UploadVideo} />          
          <Route path="/detailsdevjourn" component={AssetDetailsPageDev} />
          <Route path="/detailsjourn" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationjourn" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedjourn" component={ThankyouPasswordChanged} />
          <Route path="/buyticketjourn" component={BuyTicket} />
          <Route path="/collectaninviteasvipjourn" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinvitejourn" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventjourn" component={ConfirmParticipationToEvent} />
          <Route path="/createordinaryinvitejourn" component={CreateOrdinaryInvite} />
          <Route path="/createvipinvitejourn" component={CreateVIPInvite} />
          <Route path="/createeventticketjourn" component={CreateEventTicket} />
          <Route path="/displayinvitejourn" component={DisplayInvite} />
          <Route path="/displayperformancejourn" component={DisplayPerformance} />
          <Route path="/displayeventticketjourn" component={DisplayEventTicket} />
          <Route path="/displaymydashboardjourn" component={DisplayMyDashboard} />
          {/* <Route path="/manageeventjourn" component={ManageEvent} />
         
          <Route path="/seeparticipantsjourn" component={SeeParticipants} /> */}
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(journalismBody);
