import { useTranslation } from 'react-i18next';
import { React,useState} from "react";
import axios from "axios";
// import GetApis from '../pages/GetApis';
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import {NavLink, HashRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { Redirect } from "react-router";
import GlobalConfig from "../config.json";
import GetEnvironment from "../services/getEnvironment"

const SuperUserDashboard = () => {

        const { t } = useTranslation(); 
        
        function navigateToMessages (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'messagedashboard'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'messagedashboard'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'messagedashboard'
        }

        function navigateToUsers (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'usersservice'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'usersservice'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'usersservice'
        }

        function navigateToServiceSubscription (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'servicesubscription'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'servicesubscription'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'servicesubscription'
        }

        return (             
        <div className='justify-content'>
        <Row className="margin-maker">    
        <Col md={{ span: 6, offset: 3}}>    
        <Button variant="info" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToMessages(true)} type ='submit'> Messages</Button>     
        
        <Button variant="info" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToServiceSubscription(true)} type ='submit'> Service subscription</Button>
        
        <Button variant="info" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToUsers(true)} type ='submit'> Find a Users's dashboard</Button>
        
        </Col>        
        </Row>
        </div>
         )
    }  

export default SuperUserDashboard
