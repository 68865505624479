import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Customevents from "./categories/customevents/Customevents";
import TVBroadcastsBody from "./categories/tvBroadcasts/TVBroadcastsBody";
import RadioBroadcastsBody from "./categories/radioBroadcasts/RadioBroadcastsBody";
import SocialMediasBody from "./categories/socialMedias/SocialMediasBody";
import OnlineArticlesBd from "./categories/onlineArticles/OnlineArticlesBd";
import NewsPapersBody from "./categories/newsPapers/NewsPapersBody";
import JournalismBody from "./categories/inhouseArticles/JournalismBody";
import MiscellaneousBody from "./categories/miscellaneous/MiscellaneousBody";
import Miscellaneous from "./categories/miscellaneous/Miscellaneous";
import InHouseArticle from "./categories/inhouseArticles/Journalism";
import TVBroadcasts from "./categories/tvBroadcasts/TVBroadcasts";
import RadioBroadcasts from "./categories/radioBroadcasts/RadioBroadcasts";
import SocialMedias from "./categories/socialMedias/SocialMedias";
import OnlineArticles from "./categories/onlineArticles/OnlineArticlesLP";
import NewsPapers from "./categories/newsPapers/NewsPapers";
import PartyTicketPage from "./categories/socialMedias/booking/TicketPage";
import HomeDashboard from"./HomeDashboard";
import CustomEventsBody from "./categories/customevents/CustomEventBody";
import Disclaimer from "./footer/disclaimer";
import Help from "./footer/help";
import Follow from "./footer/followus";
import Join from "./footer/joinus";
import Ask from "./footer/askus";
import CollectATicket from "./categories/collections/CollectATicket";
import MakePaymentBankTransfert  from "./payments/MakePaymentBankTransfert";
import MakePaymentBp  from "./payments/MakePaymentbp";
import MakePaymentOl  from "./payments/MakePaymentol";
import CreateEventBase from "./categories/createanevent/CreateEventBase";
import ThankyouAfterUploaded from "./categories/createanevent/ThankyouAfterUploaded";
import UploadPictures from "./categories/createanevent/UploadPictures";
import UploadVideo from "./categories/createanevent/UploadVideo";
import Register from "./registerlogin/RegisterUser"
import MakePaymentRoot  from "./payments/MakePaymentRoot";
import AdminBody from "./admin/AdminDashBoardBody";
import ServiceSubscription from "./admin/ServiceSubscription";

class Main extends Component {
  render() {
    return (
      <div>   
        <AdminBody/>       
        <OnlineArticlesBd/> 
        <SocialMediasBody />
        <NewsPapersBody/>
        <MiscellaneousBody/>
        <CustomEventsBody/>
        <TVBroadcastsBody/>
        <RadioBroadcastsBody/>
        <JournalismBody/>      
        <HashRouter>
        <Route exact path="/" component={HomeDashboard} />
          <Route exact path="/home" component={HomeDashboard} />      
          <Route path="/homedashboard" component={HomeDashboard} />   
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/help" component={Help} />          
          <Route path="/followus" component={Follow} />
          <Route path="/joinus" component={Join} />         
          <Route path="/contact" component={Ask} />
          <Route path="/askus" component={Ask} />
          <Route path="/registersgeneral" component={Register} />
          <Route path="/collectaticketpage" component={CollectATicket} /> 
          <Route path="/partyticketpage" component={PartyTicketPage} /> 
          <Route path="/makepaymentbt" component={MakePaymentBankTransfert} /> 
          <Route path="/makepaymentbp" component={MakePaymentBp} /> 
          <Route path="/makepaymentol" component={MakePaymentOl} /> 
          <Route path="/makepaymentroot" component={MakePaymentRoot} />           
          <Route path="/socialmedias" component={SocialMedias} />
          <Route path="/newspapers" component={NewsPapers} /> 
          <Route path="/tvbroadcasts" component={TVBroadcasts} />
          <Route path="/radiobroadcasts" component={RadioBroadcasts} />
          <Route path="/onlinearticles" component={OnlineArticles} />
          <Route path="/miscellaneous" component={Miscellaneous} />
          <Route path="/inhousearticles" component={InHouseArticle} />           
          <Route path="/customevents" component={Customevents} />         
          <Route path="/uploadassets" component={CreateEventBase} /> 
          <Route path="/thankyouuploaded" component={ThankyouAfterUploaded} />
          <Route path="/uploadpictures" component={UploadPictures} /> 
          <Route path="/uploadvideos" component={UploadVideo} /> 
          <Route path="/servicesubscription" component={ServiceSubscription} /> 
                 
         <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(Main);
