import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Contact from "../../footer/askus";
import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";
import ServiceSubscription from "./dashboard/ServiceSubscriptionNpps";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";
import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
import BuyTicket from "./booking/BuyATicket";
import CollectAnInviteAsVIP from "./booking/CollectAVIPInvite";
import CollectInvite from "./booking/CollectInvite";
import ConfirmParticipationToEvent from "./booking/CollectInvite";
import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";
import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";
import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";
import Help from "./help";
import Visits from "./UserVisits";

import GeneratePerformance from "./pages/performancesandorders/Performances/GeneratePerformance";
import Performances from "./pages/performancesandorders/Performances/PerformancePage";

class NewsPapersBody
 extends Component {
  render() {
    return (
        <HashRouter> 
          <Route path="/helpnpps" component={Help} />      
          <Route path="/visitsnpps" component={Visits} />              
          <Route path="/loginscreennpps" component={Loginscreen} />   
          <Route path="/contactnpps" component={Contact} />     
          <Route path="/registerusernpps" component={RegisterUser} />
          <Route path="/performancesnpps" component={Performances} /> 
          <Route path="/generateperformancenpps" component={GeneratePerformance} /> 
          <Route path="/changepasswordnpps" component={ChangePassword} /> 
          <Route path="/loginnpps" component={Login} /> 
          <Route path="/logoutnpps" component={Logout} /> 
          <Route path="/servicesubnpps" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadednpps" component={ThankYouUploaded} /> 
          <Route path="/uploadassetsnpps" component={UploadAssets} />
          <Route path="/uploadpicturesnpps" component={UploadPictures} />
          <Route path="/uploadvideosnpps" component={UploadVideo} />          
          <Route path="/detailsdevnpps" component={AssetDetailsPageDev} />
          <Route path="/detailsnpps" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationnpps" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangednpps" component={ThankyouPasswordChanged} />
          <Route path="/buyticketnpps" component={BuyTicket} />
          <Route path="/collectaninviteasvipnpps" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinvitenpps" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventnpps" component={ConfirmParticipationToEvent} />
          <Route path="/createordinaryinvitenpps" component={CreateOrdinaryInvite} />
          <Route path="/createvipinvitenpps" component={CreateVIPInvite} />
          <Route path="/createeventticketnpps" component={CreateEventTicket} />
          <Route path="/displayinvitenpps" component={DisplayInvite} />
          <Route path="/displayperformancenpps" component={DisplayPerformance} />
          <Route path="/displayeventticketnpps" component={DisplayEventTicket} />
          <Route path="/displaymydashboardnpps" component={DisplayMyDashboard} />
          {/* <Route path="/manageeventnpps" component={ManageEvent} />
         
          <Route path="/seeparticipantsnpps" component={SeeParticipants} /> */}
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(NewsPapersBody);
