import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
// import Services from "../clientappHairtechnicians/pages/Services";
import Contact from "../../footer/askus";

import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";

// import ServiceSubscription from "../siteAdmin/customerfeatures/ServiceSubscription";
import ServiceSubscription from "./dashboard/ServiceSubscriptionCustomEvents";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";

import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";


import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";

import BuyTicket from "./booking/BuyATicket";
import CollectAnInviteAsVIP from "./booking/CollectAVIPInvite";
import CollectInvite from "./booking/CollectInvite";
import ConfirmParticipationToEvent from "./booking/CollectInvite";
// import ApproximateCostPerParticipant from "../../payment/ApproximateCostPerParticipant";
// // import ApproximateCostOfEvent from "../../payment/ApproximateCostPerEvent";

import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";

import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";

import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";


// import MakePayment from "../../payments/MakePaymentRoot";

import Help from "./help";
import Visits from "./UserVisits";

import GeneratePerformance from "./pages/performancesandorders/Performances/GeneratePerformance";
import Performances from "./pages/performancesandorders/Performances/PerformancePage";

class miscellaneousBody extends Component {
  render() {
    return (
        <HashRouter> 
          <Route path="/helpcuevt" component={Help} />      
          <Route path="/visitscuevt" component={Visits} />              
          <Route path="/loginscreencuevt" component={Loginscreen} />   
          <Route path="/contactcuevt" component={Contact} />     
          <Route path="/registerusercuevt" component={RegisterUser} />
          <Route path="/performancescuevt" component={Performances} /> 
          <Route path="/generateperformancecuevt" component={GeneratePerformance} /> 
          <Route path="/changepasswordcuevt" component={ChangePassword} /> 
          <Route path="/logincuevt" component={Login} /> 
          <Route path="/logoutcuevt" component={Logout} /> 
          <Route path="/servicesubcuevt" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedcuevt" component={ThankYouUploaded} /> 
          <Route path="/uploadassetscuevt" component={UploadAssets} />
          <Route path="/uploadpicturescuevt" component={UploadPictures} />
          <Route path="/uploadvideoscuevt" component={UploadVideo} />          
          <Route path="/detailsdevcuevt" component={AssetDetailsPageDev} />
          <Route path="/detailscuevt" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationcuevt" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedcuevt" component={ThankyouPasswordChanged} />
          <Route path="/buyticketcuevt" component={BuyTicket} />
          <Route path="/collectaninviteasvipcuevt" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinvitecuevt" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventcuevt" component={ConfirmParticipationToEvent} />        
          <Route path="/createordinaryinvitecuevt" component={CreateOrdinaryInvite} />
          <Route path="/createvipinvitecuevt" component={CreateVIPInvite} />
          <Route path="/createeventticketcuevt" component={CreateEventTicket} />
          <Route path="/displayinvitecuevt" component={DisplayInvite} />
          <Route path="/displayperformancecuevt" component={DisplayPerformance} />
          <Route path="/displayeventticketcuevt" component={DisplayEventTicket} />
          <Route path="/manageeventcuevt" component={ManageEvent} />
          <Route path="/displaymydashboardcuevt" component={DisplayMyDashboard} /> 
          <Route path="/seeparticipantscuevt" component={SeeParticipants} />
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(miscellaneousBody);
